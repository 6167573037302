import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const ReqAccess = () => {
  const [requestSent, setRequestSent] = useState(false);
  const [isFocused, setIsFocused] = useState(false); // New state for textarea focus
  const [textareaValue, setTextareaValue] = useState(""); // New state for textarea content
  const navigate = useNavigate();

  const logoutAction = () => {
    navigate("/logout");
  };

  const requestAction = () => {
    setRequestSent(true); // Set request as sent
    
    var sessionToken = cookies.get('sessionToken', { path: '/' });
    const config = { headers: { 'x-access-token': sessionToken } };
    var apiPath = process.env.REACT_APP_PATH_DESTINATION + "/requestingAccess";
  
    // Create a FormData object
    const formData = new FormData();
    formData.append('txt', textareaValue || "Tidak ada pesan"); // Use textareaValue or default text
    formData.append('appName', "Helpdesk");

    axios.post(apiPath, formData, config)
      .then(res => {
        console.log(res.data);
      })
      .catch(error => {
        console.error("Error post:", error);
      });
  };

  return (
    <>
      <div style={styles.container}>
        <img src="/ReqAccess.png" alt="Request Access" style={styles.image} />
        {requestSent ? (
          <>
            <h2 style={styles.title}>Request Sent</h2>
            <p style={styles.description}>
              Kami sedang memproses request Anda. Harap cek aplikasi secara berkala.
            </p>
          </>
        ) : (
          <>
            <h2 style={styles.title}>Request Access</h2>
            <p style={styles.description}>
              Akun Anda tidak memiliki akses untuk membuka aplikasi ini. 
              Lakukan Request Access dengan klik tombol di bawah.
            </p>
            <textarea 
              placeholder="Pesan (opsional)" 
              style={{
                ...styles.textarea,
                borderColor: isFocused ? "#0296D2" : "#ccc",
                outlineColor: isFocused ? "#0296D2" : "initial",
              }}
              value={textareaValue}  // Bind textareaValue to the textarea
              onChange={(e) => setTextareaValue(e.target.value)}  // Update textareaValue on change
              onFocus={() => setIsFocused(true)}  // Set focus state on
              onBlur={() => setIsFocused(false)}   // Remove focus state on blur
            ></textarea>
            <button onClick={requestAction} style={styles.button}>Request Access</button>
          </>
        )}
        <button onClick={logoutAction} style={styles.logoutButton}>Logout from This Account</button>
      </div>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "400px",
    margin: "0 auto",
    padding: "20px",
  },
  image: {
    width: "280px",
    marginBottom: "20px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
    fontFamily: 'Blender Pro',
  },
  description: {
    fontSize: "16px",
    textAlign: "center",
    marginBottom: "20px",
    fontFamily: 'Blender Pro',
  },
  textarea: {
    width: "100%",
    minHeight: "80px",
    marginBottom: "20px",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ccc",  // Default border color
    fontFamily: 'Blender Pro',
    transition: "border-color 0.3s",  // Smooth transition for border color change
  },
  button: {
    backgroundColor: "#0296D2",
    color: "#fff",
    padding: "10px 20px",
    fontSize: "16px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    marginBottom: "10px",
    fontFamily: 'Blender Pro',
  },
  logoutButton: {
    backgroundColor: "transparent",
    color: "#333",
    padding: "10px 20px",
    fontSize: "14px",
    border: "none",
    cursor: "pointer",
    textDecoration: "underline",
    fontFamily: 'Blender Pro',
  },
};

export default ReqAccess;
