import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import NoPage from '../../NoPage';
import useOrientation from '../../../general/useOrientation';
const cookies = new Cookies();
function TabbingTenantConfig({selectedTab, handleTabClick}) {
  var sessionData = cookies.get('sessionData', { path: '/' })
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}

  if(parseInt(sessionData.int_ext) === 1 && parseInt(sessionData.apps_roles_id) < 4 && orientation === 'landscape'){
    return (
      <div className='row' style={{ position:'relative', zIndex:'2',borderBottom: '1px solid #E8E9EB', width: '98%', marginTop: '3vw'}}>
        <div className={`tabbingBig ${selectedTab === 'Tenant Management' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Tenant Management')}>
          Tenant Management
        </div>
        <div className={`tabbingVeryBig ${selectedTab === 'Tenant User Management' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Tenant User Management')}>
          Tenant User Management
        </div>
        <div className={`tabbingVeryBig ${selectedTab === 'RKL RPL Management' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('RKL RPL Management')}>
          RKL RPL Management
        </div>
      </div>
    )
  } else if (parseInt(sessionData.int_ext) === 1 && parseInt(sessionData.apps_roles_id) < 4 && orientation === 'portrait'){
    return (
      <div className='row' style={{ position:'relative', zIndex:'2',borderBottom: '1px solid #E8E9EB', width: '98%', marginTop: '3vw', marginBottom: '2vh', marginLeft:'2vw'}}>
        <div className={`tabbingMobile ${selectedTab === 'Tenant Management' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Tenant Management')}>
          Tenant Management
        </div>
        <div className={`tabbingMobile ${selectedTab === 'Tenant User Management' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Tenant User Management')}>
          Tenant User Management
        </div>
        <div className={`tabbingMobile ${selectedTab === 'RKL RPL Management' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('RKL RPL Management')}>
          RKL RPL Management
        </div>
      </div>
    )
  } else {
    return (
      <NoPage />
    )
  }
  
}

export default TabbingTenantConfig;
