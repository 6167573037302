import React from 'react';

function ConfigCheckbox({ name, data, setData }) {
  const handleCheckboxChange = () => {
    setData(data == 1 ? 0 : 1);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', margin: '1vw' }}>
      <input
        type="checkbox"
        style={{ width: '1.5vw', height: '1.5vw' }}
        className="form-control cursorPointer"
        checked={parseInt(data) === 1}
        onChange={handleCheckboxChange}
      />
      <p
        onClick={handleCheckboxChange}
        style={{ fontSize: '1vw', cursor: 'pointer', userSelect: 'none' }}
      >
        <b>{name}</b>
      </p>
    </div>
  );
}

export default ConfigCheckbox;
