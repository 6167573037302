import React, { useEffect, useState } from 'react'
import HeaderPage from '../../headerPage/HeaderPage';
import Cookies from 'universal-cookie'; import axios from 'axios'
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import { useParams } from 'react-router-dom';
import ConfigDropdown from '../configHelper/ConfigDropdown';
import ConfigInputText from '../configHelper/ConfigInputText';
import useOrientation from '../../../general/useOrientation';
import ConfigCheckbox from '../configHelper/ConfigCheckbox';

const cookies = new Cookies();
const MySwal = withReactContent(Swal)
function ConfigViewRR({addEdit, changeNavState}) {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [tenantId, setTenantId] = useState('1');
  const [nama, setNama] = useState('');
  const [entitas, setEntitas] = useState('');
  const [alamat, setAlamat] = useState('');
  const [blok, setBlok] = useState('');
  const [doc_status, set_doc_status] = useState('');
  const [doc_year, set_doc_year] = useState('');
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  useEffect(() => {
    if (addEdit == "edit") {
      getData();
    }
  }, [addEdit])

  useEffect(() => {
    changeNavState('Configurations');
  }, [])

  const pickDropdownTenantId = (val) => {
    setTenantId(val.value);
  }

  const getData = () => {
    setIsLoading(true);
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/rrView/${id}`) 
      .then(res => {
        console.log(res.data.data);
        setTenantId(res.data.data.tenant_id)
        setNama(res.data.data.nama)
        setEntitas(res.data.data.entitas)
        setAlamat(res.data.data.alamat)
        setBlok(res.data.data.blok)
        set_doc_status(res.data.data.doc_status)
        set_doc_year(res.data.data.doc_year)
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        MySwal.fire({
          title: <strong>Error!</strong>,
          html: <p>{err.response?.data?.message || "An error occurred. Please try relogging"}</p>
        })
        setIsLoading(false);
      })
  }

  const submitData = () => {
    //postData
    let data = {
      tenantId : tenantId,
      nama : nama,
      entitas : entitas,
      alamat : alamat,
      blok : blok,
      doc_status : doc_status,
      doc_year : doc_year,
      id: id
    }

    setIsLoading(true);
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    let apiLink = "";
    if (addEdit === "edit"){
      apiLink=`${process.env.REACT_APP_PATH_DESTINATION}/rrEdit`
    } else if (addEdit === "add"){
      apiLink=`${process.env.REACT_APP_PATH_DESTINATION}/rrAdd`
    }
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.post(apiLink,data) 
      .then(res => {
        console.log(res.data.data);
        setIsLoading(false);
        MySwal.fire({
          title: <strong>Success</strong>,
          html: <p>Data Edited</p>
        })
      })
      .catch(err => {
        console.log(err);
            MySwal.fire({
            title: <strong>Error!</strong>,
            html: <p>{err.response?.data?.message || "An error occurred. Please try relogging"}</p>
        })
        setIsLoading(false);
      })
  }

  return (
  <>
    <HeaderPage
        title1={"Configurations"}
        title2={"RKL RPL"}
        link1={"/configuration/tenant"}
        loadingState={isLoading}
    />

    <div style={{marginTop:'4vw', marginLeft:isThisPortrait?'5vw':'', marginRight:'1vw'}} className='row'>
      <div className='form-group'>
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigDropdown defValue={tenantId} type={9} pickDropdown={pickDropdownTenantId} title="Tenant Parent Name" link={"/tenantMgmtList"} isMulti={false}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"Nama Perusahaan"} data={nama} setData={setNama}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"Entitas"} data={entitas} setData={setEntitas}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"Alamat"} data={alamat} setData={setAlamat}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"Blok"} data={blok} setData={setBlok}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"Status Dokumen RKL RPL"} data={doc_status} setData={set_doc_status}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"Tahun Dokumen (Jika Ada)"} data={doc_year} setData={set_doc_year}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <button type='submit' onClick={submitData} className='btn buttonBlue' style={{marginTop:'1vh'}}>
          Submit
        </button>
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
      </div>
    </div>    
  </>
  )
}

export default ConfigViewRR