import ReactDOM from "react-dom/client"; import { useState, useEffect} from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./pages/layout/Navbar"; import NoPage from "./pages/menu/NoPage";
import Settings from "./pages/menu/Settings";
import Dashboard from "./pages/menu/Dashboard"; import Logout from "./pages/menu/Logout";
import 'semantic-ui-css/semantic.min.css';
import Cookies from 'universal-cookie'; import axios from 'axios' ; 
import Login from "./pages/menu/login/Login"; import Token from "./pages/menu/Token";
import BackToPortal from "./pages/menu/BackToPortal";
import ApprovalDetailList from "./pages/menu/approval/ApprovalDetailList";
import ApprovalDetailsAdd from "./pages/menu/approval/ApprovalDetailsAdd";
import ApprovalDetailEdit from "./pages/menu/approval/ApprovalDetailEdit";
import PdDetailsList from "./pages/menu/personalDash/PdDetailsList";
import PdDetailsAdd from "./pages/menu/personalDash/PdDetailsAddNew";
import PdDetailsEdit from "./pages/menu/personalDash/PdDetailsEdit";
import Audit from "./pages/menu/audit/Audit";
import DocDashMaster from "./pages/menu/docDash/DocDashMaster";
import FormExample from "./pages/content/FormExample";
import BasicTabs from "./pages/menu/BasicTabs";
import PdfEditor from "./pages/menu/pdfDragDrop/PdfEditor";
import FormCreator from "./pages/menu/form/FormCreator";
import FormSubmit from "./pages/menu/form/FormSubmit";
import FormRules from "./pages/menu/form/FormRules";
import FormShow from "./pages/menu/form/FormShow";
import DashboardTwo from "./pages/menu/dashboard/DashboardTwo";
import DashboardThree from "./pages/menu/DashboardThree";
import AuditDoc from "./pages/menu/audit/AuditDoc";
import "./font/blender-pro/stylesheet.css"
import CreateTicket from "./pages/menu/createTicket/CreateTicket";
import TicketList from "./pages/menu/ticketList/TicketList";
import TicketDetails from "./pages/menu/ticketDetail/TicketDetails";
import CreateTicketParent from "./pages/menu/createTicket/CreateTicketParent";
import AnnouncementListParent from "./pages/menu/announcement/AnnouncementListParent";
import DocumentListParent from "./pages/menu/documentList/DocumentListParent";
import CreateDocument from "./pages/menu/document/CreateDocument";
import DocumentDetails from "./pages/menu/documentDetail/DocumentDetails";
import Reports from "./pages/menu/report/Reports";
import FaqHome from "./pages/menu/faq/FaqHome";
import Configuration from "./pages/menu/configuration/Configuration";
import ConfigList from "./pages/menu/configuration/configTicket/ConfigList";
import ConfigViewParent from "./pages/menu/configuration/configTicket/ConfigViewParent";
import ConfigViewCat from "./pages/menu/configuration/configTicket/ConfigViewCat";
import ConfigViewDeptAccess from "./pages/menu/configuration/configTicket/ConfigViewDeptAccess";
import ConfigAnnouncement from "./pages/menu/configuration/singleSection/ConfigAnnouncement";
import ConfigAncView from "./pages/menu/configuration/singleSection/ConfigAncView";
import ProfileEdit from "./pages/menu/dashboard/ProfileEdit";
import PrivacyPolicy from "./pages/menu/login/PrivacyPolicy";
import NewPass from "./pages/menu/login/NewPass";
import FaqAddEdit from "./pages/menu/faq/FaqAddEdit";
import DocLibAddEdit from "./pages/menu/documentLibrary/DocLibAddEdit";
import ConfigTenantList from "./pages/menu/configuration/configTenant/ConfigTenantList";
import ConfigViewTenantMgmt from "./pages/menu/configuration/configTenant/ConfigViewTenantMgmt";
import ConfigViewTenantUser from "./pages/menu/configuration/configTenant/ConfigViewTenantUser";
import useOrientation from "./pages/general/useOrientation";
import { GeolocationProvider } from "./pages/general/GeolocationContext";
import ReqAccess from "./pages/layout/ReqAccess";
import ConfigViewRR from "./pages/menu/configuration/configTenant/ConfigViewRR";

const cookies = new Cookies();
export default function App() {
  const orientation = useOrientation();
  const [navState, setNavState] = useState("Dashboard");
  const changeNavState = (stateText) => {
    setNavState(stateText);
  }
  
  useEffect(() => {
    // Function to refresh the page
    const refreshPage = () => {
      window.location.reload();
    };

    // Event listener to detect orientation change and refresh the page
    window.addEventListener('orientationchange', refreshPage);

    return () => {
      window.removeEventListener('orientationchange', refreshPage);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  useEffect(() => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    console.log("Session Token", sessionToken)
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/getSessionHD`) 
      .then(res => {
        if (res.data.status == "200") {
          //Session Detected. Getting the data
          console.log("session exist"); cookies.set('sessionExist', true, { path: '/' });

          cookies.set('sessionData', res.data.data, { path: '/' });
          /*
          axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
          axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/getAllowView`) 
          .then(res => {
            if (res.data.status == "200") {
              console.log("allow view"); cookies.set('allowView', true, { path: '/' });
            } else {
              console.log("NOT allow view"); cookies.set('allowView', false, { path: '/' });
              // Check if not already on the requestAccess page
              if (window.location.pathname !== "/requestAccess") {
                window.location.replace("/requestAccess");
              }
            }
          })
          .catch(err => {
            console.log(err);
          })
          */
        } else {
          //No session detected. Deleting All known session
          cookies.remove('sessionToken');cookies.remove('sessionData');cookies.remove('sessionExist');cookies.remove('allowView');
        }
      })
      .catch(err => {
        //Session Error
        //alert("session error");
        cookies.remove('sessionToken');cookies.remove('sessionData');cookies.remove('sessionExist');cookies.remove('allowView');
        console.log(err);
      })
  }, [])
  return (
    <GeolocationProvider>
    <BrowserRouter>
      <Routes>
      <Route
            path="/"
            element={
              cookies.get('sessionExist') ? (
                <Navbar navState={navState} changeNavState={changeNavState} />
              ) : (
                <Navigate replace to="/login" />
              )
            }
          >
            <Route index element={<DashboardTwo changeNavState={changeNavState}/>}   />
            <Route path="createTicket" element={<CreateTicketParent changeNavState={changeNavState} />}  />
            <Route path="CreateTicketDraft/:id" element={<CreateTicketParent changeNavState={changeNavState} isDraft="1"/>}  />
            <Route path="createTicketReferral/:id" element={<CreateTicketParent changeNavState={changeNavState} />}  />
            <Route path="dashboard" element={<DashboardTwo changeNavState={changeNavState}/>} />
            <Route path="ticketList" element={<TicketList changeNavState={changeNavState}/>} />
            <Route path="ticketList/:byTo/:id/:name" element={<TicketList changeNavState={changeNavState}/>} />
            <Route path="ticketDetail/:id" element={<TicketDetails changeNavState={changeNavState}/>} />
            <Route path="docDetail/:id" element={<DocumentDetails changeNavState={changeNavState}/>} />
            <Route path="announcement" element={<AnnouncementListParent changeNavState={changeNavState}/>} />
            <Route path="announcement/:search" element={<AnnouncementListParent changeNavState={changeNavState}/>} />
            <Route path="documentList" element={<DocumentListParent changeNavState={changeNavState}/>} />
            <Route path="addDocLib" element={<DocLibAddEdit addEdit={"add"} changeNavState={changeNavState} />}  />
            <Route path="editDocLib/:id" element={<DocLibAddEdit addEdit={"edit"} changeNavState={changeNavState} />}  />
            <Route path="requestDocument" element={<CreateDocument changeNavState={changeNavState}/>} />
            <Route path="reports" element={<Reports changeNavState={changeNavState}/>} />
            <Route path="reportsSummary" element={<Reports sum={true} changeNavState={changeNavState}/>} />
            <Route path="faq" element={<FaqHome changeNavState={changeNavState}/>} />
            <Route path="faq/:id" element={<FaqHome changeNavState={changeNavState}/>} />
            <Route path="faq/add" element={<FaqAddEdit addEdit={"add"} changeNavState={changeNavState}/>} />
            <Route path="faq/edit/:id" element={<FaqAddEdit addEdit={"edit"} changeNavState={changeNavState}/>} />
            <Route path="audit" element={<Audit changeNavState={changeNavState}/>} />
            <Route path="configuration" element={<Configuration changeNavState={changeNavState}/>} />
            <Route path="configuration/ticket" element={<ConfigList changeNavState={changeNavState}/>} />
            <Route path="configuration/ticket/parent/:intExtDoc/:id" element={<ConfigViewParent addEdit={"edit"} changeNavState={changeNavState}/>} />
            <Route path="configuration/ticket/cat/:intExtDoc/:id" element={<ConfigViewCat addEdit={"edit"} changeNavState={changeNavState}/>} />
            <Route path="configuration/ticket/deptaccess/:id" element={<ConfigViewDeptAccess addEdit={"edit"} changeNavState={changeNavState}/>} />
            <Route path="configuration/add/parent/:intExtDoc" element={<ConfigViewParent addEdit={"add"} changeNavState={changeNavState}/>} />
            <Route path="configuration/add/cat/:intExtDoc" element={<ConfigViewCat addEdit={"add"} changeNavState={changeNavState}/>} />
            <Route path="configuration/add/deptaccess" element={<ConfigViewDeptAccess addEdit={"add"} changeNavState={changeNavState}/>} />
            <Route path="configuration/announcement" element={<ConfigAnnouncement linkData={"ticketAncList"} clickLink={"/configuration/announcement/view/"} changeNavState={changeNavState}/>} />
            <Route path="configuration/announcement/view/:id" element={<ConfigAncView addEdit={"edit"} changeNavState={changeNavState}/>} />
            <Route path="configuration/createConfigAnnouncement" element={<ConfigAncView addEdit={"add"} changeNavState={changeNavState}/>} />
            <Route path="profileEdit" element={<ProfileEdit changeNavState={changeNavState} />}  />
            <Route path="configuration/tenant" element={<ConfigTenantList changeNavState={changeNavState}/>} />
            <Route path="configuration/edit/tenantMgmt/:id" element={<ConfigViewTenantMgmt addEdit={"edit"} changeNavState={changeNavState}/>} />
            <Route path="/configuration/add/tenantMgmt" element={<ConfigViewTenantMgmt addEdit={"add"} changeNavState={changeNavState}/>} />
            <Route path="/configuration/edit/tenantUser/:id" element={<ConfigViewTenantUser addEdit={"edit"} changeNavState={changeNavState}/>} />
            <Route path="/configuration/add/tenantUser" element={<ConfigViewTenantUser addEdit={"add"} changeNavState={changeNavState}/>} />
            <Route path="/configuration/edit/rr/:id" element={<ConfigViewRR addEdit={"edit"} changeNavState={changeNavState}/>} />
            <Route path="/configuration/add/rr" element={<ConfigViewRR addEdit={"add"} changeNavState={changeNavState}/>} />

            <Route path="pdash" element={<DashboardThree />} />
            <Route path="viewAll" element={<Dashboard />} />
            <Route path="dashboard/:type" element={<DocDashMaster />} />
            <Route path="dashboard/:type/:deptIDChosen/:deptNameChosen" element={<DocDashMaster />} />
            <Route path="blogs" element={<Settings />} />
            <Route path="formCreator/:id/:docName" element={<FormCreator />} />
            <Route path="formSubmit/:id/:deptId/:docName/:dadID" element={<FormSubmit />} />
            <Route path="showDoc/:id" element={<FormShow />} />
            <Route path="showDoc/:id/:backCode" element={<FormShow />} />
            <Route path="pdf" element={<PdfEditor />} />

            <Route path="auditDoc" element={<AuditDoc />} />
            <Route path="approvalDetailsList" element={<ApprovalDetailList />} />
            <Route path="addNewAPD" element={<ApprovalDetailsAdd />} />
            <Route path="editAPD/:id" element={<ApprovalDetailEdit />} />
            <Route path="personalDashboard" element={<PdDetailsList />} />
            <Route path="addNewPD" element={<PdDetailsAdd />} />
            <Route path="editPD/:id" element={<PdDetailsEdit />} />
            <Route path="formRules/:id/:docName" element={<FormRules />} />
            <Route path="exampleForm/:type" element={<FormExample />} />
            <Route path="pdashSetting" element={<PdDetailsList />} />

          
        </Route>
        
        <Route path="login" element={cookies.get('sessionExist') ? (<Navigate replace to={"/dashboard"} />) :  (<Login />)}/>
        <Route path="logout" element={<Logout />} />
        <Route path="backToPortal" element={<BackToPortal />} />
        <Route path="basictabs" element={<BasicTabs />} />
        <Route path="/portalLogin/:token" element={<Token />} />
        <Route path="privacyPolicy" element={<PrivacyPolicy />}  />
        <Route path="/newpass/:token" element={<NewPass />}  />
        <Route path="requestAccess" element={<ReqAccess />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
    </GeolocationProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);