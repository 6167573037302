import React, { useRef } from 'react'; import axios from 'axios' ;
import ContentHeader from '../../../content/ContentHeader'; import { useState, useEffect} from 'react';
import Cookies from 'universal-cookie'; 
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import BootstrapTable from 'react-bootstrap-table-next'
import {Search as NoirSearch } from 'iconoir-react'
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { useNavigate } from 'react-router-dom';

import { Tooltip } from 'react-tooltip';
import SwitchOnOff from '../configTicket/SwitchOnOff';
import useOrientation from '../../../general/useOrientation';

const cookies = new Cookies();
const MySwal = withReactContent(Swal)
const { SearchBar } = Search;

function ConfigTenantTable({link, refresh, setLoading, clickLink, filterData,parentOrCat}) {
  const clickedColumnIndex = useRef();
  const navigate = useNavigate();
  const displayedRow = 12;
  const [dataa, setDataa] = useState([]);
  const [totlength, setTotLength] = useState(0);
  const [txtBL, setTxtBL] = useState('');
  const [currentPage, setCurrentPage] = useState(0); // Track the current page
  var sessionData = cookies.get('sessionData', { path: '/' })
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  useEffect(() => {
    setLoading(true)
    if (filterData.length > 0){
      //getFilteredData(link)
    } else {
      getData(link);
    }
  }, [link,filterData])

  useEffect(() => {
    if (filterData.length > 0){
      //getFilteredData(link)
    } else {
      getData(link);
    }
  }, [refresh])

  useEffect(() => {
    let cpmone = parseInt(currentPage) - 1 
    let firstDigit = (parseInt(displayedRow) * parseInt(cpmone) )
    let secondDigit = parseInt(firstDigit) + parseInt(displayedRow)
    if (secondDigit > totlength) secondDigit = totlength
    let a = `${firstDigit+1} - ${secondDigit} of ${totlength} data`   ;
    setTxtBL(a);
    if (totlength === 0){
      setTxtBL();
    }
  }, [currentPage,totlength])
  
  const activeFormatter = (data,row) => {
    return <SwitchOnOff setLoading={setLoading} link={link} initialActive={data} data={row.id}/>
  }

  const getData = (link) => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/${link}`) 
      .then(res => {
        console.log(res.data.data);
        setDataa(res.data.data);
        setTotLength(res.data.data.length);
        setCurrentPage(1);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
            MySwal.fire({
            title: <strong>Error!</strong>,
            html: <p>{err.response?.data?.message || "An error occurred. Please try relogging"}</p>
        })
        setLoading(false);
      })
  }

  const getFilteredData = (link) => {
    console.log("logging filter data before hit API",filterData)
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.post(`${process.env.REACT_APP_PATH_DESTINATION}/${link}`, { data: filterData }) 
      .then(res => {
        console.log("getfiltered data logging",res.data.data);
        setDataa(res.data.data[0]);
        setTotLength(res.data.data.length);
        setCurrentPage(1);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
            MySwal.fire({
            title: <strong>Error!</strong>,
            html: <p>{err.response?.data?.message || "An error occurred. Please try relogging"}</p>
        })
        setLoading(false);
      })
  }
  const options = {
    sortIndicator: true,
    bordered: false,
    hideSizePerPage: true, // Hide the option to change the number of rows
    sizePerPage: displayedRow, // You can set your desired default value here
    page: 1,
    showTotal: false, // Hide the total number of rows
    sizePerPageList: [] // Remove the size per page options from the dropdown
  };
  const handlePageChange = (type, { page, sizePerPage }) => {
    setCurrentPage(type); 
  };
  const searchProps = {
    placeholder:isThisPortrait? "search..." :"search config...",
    className: "searchRight",
    zIndex : 5,
    position: 'relative',
    height: '40px',
    fontFamily: 'Blender Pro !important'
  };

  const columnsParent = [
    {
      text: '#',
      dataField: 'index',
      sort: true,
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: (column, colIndex) => {
        return { width: '80px' }; // Adjust the width of the index column
      },
    },
    {
      text: 'Nama PT',
      dataField: 'nama_tenant',
      sort: true
    },
    {
      text: 'Alamat PT',
      dataField: 'alamat_tenant',
      sort: true
    },
    {
      text: 'Block PT',
      dataField: 'blok_id',
      sort: true
    },
    {
      text: 'Status',
      dataField: 'active',
      sort:true,
      formatter:activeFormatter,
      events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
              clickedColumnIndex.current = columnIndex;
          },
      },
    }
  ];

  const columnsCat = [
    {
      text: '#',
      dataField: 'index',
      sort: true,
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: (column, colIndex) => {
        return { width: '80px' }; // Adjust the width of the index column
      },
    },
    {
      text: 'Tenant Name',
      dataField: 'nama_tenant',
      sort: true
    },
    {
      text: 'PIC Display Name',
      dataField: 'display_name',
      sort:true
    },
    {
      text: 'PIC Ext Position',
      dataField: 'dept_pic',
      sort:true
    },
    {
      text: 'PIC For Dept',
      dataField: 'tipe_pic',
      sort:true
    },
    {
      text: 'Email',
      dataField: 'email',
      sort:true
    },
    {
      text: 'Handphone',
      dataField: 'no_hp',
      sort:true
    },
    {
      text: 'Status',
      dataField: 'active',
      sort:true,
      formatter:activeFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
            clickedColumnIndex.current = columnIndex;
        },
      },
    }
  ];

  const columnsRR = [
    {
      text: '#',
      dataField: 'index',
      sort: true,
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: (column, colIndex) => {
        return { width: '80px' }; // Adjust the width of the index column
      },
    },
    {
      text: 'Tenant Name',
      dataField: 'nama',
      sort: true
    },
    {
      text: 'Entitas',
      dataField: 'entitas',
      sort:true
    },
    {
      text: 'Alamat',
      dataField: 'alamat',
      sort:true
    },
    {
      text: 'blok',
      dataField: 'blok',
      sort:true
    },
    {
      text: 'Status Doc',
      dataField: 'doc_status',
      sort:true
    },
    {
      text: 'Tahun Doc',
      dataField: 'doc_year',
      sort:true
    },
    {
      text: 'Status',
      dataField: 'active',
      sort:true,
      formatter:activeFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
            clickedColumnIndex.current = columnIndex;
        },
      },
    }
  ];

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      if (parentOrCat == "cat" && clickedColumnIndex.current === 7) {
        // Do nothing - this is active inactive icon
      } else if (parentOrCat == "parent" && clickedColumnIndex.current === 4){
        //do nothing as well
      } else {
        navigate(clickLink+row.id);
      }
      // Reset clickedColumnIndex
      clickedColumnIndex.current = undefined;
    },
    onMouseEnter: (e, row, rowIndex) => {
      //console.log(`enter on row with index: ${rowIndex}`);
    }
  }
  return (
    <>
    <ContentHeader title=""/> 
        <section className="content">
        <div className="container-fluid">
            <div className="row"> 
              <div className="col-md-12">
                <ToolkitProvider
                  keyField="id"
                  data={dataa}
                  columns={parentOrCat === 'cat' ? columnsCat : parentOrCat === 'rr' ? columnsRR : columnsParent}
                  bootstrap4={true}
                  search={searchProps}
                >
                  {props => (
                    <div >
                      <div style={{ width:isThisPortrait? '25vw':'200px', float:'right',zIndex:-1 }}>          
                      <table>
                        <tbody>
                          <tr>
                          <td>
                            <SearchBar {...props.searchProps} {...searchProps} />
                          </td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        striped 
                        hover
                        pagination={paginationFactory({ ...options, onPageChange: handlePageChange })}
                        classes="custom-table" 
                        rowClasses="custom-row"
                        rowEvents={ tableRowEvents }
                      />
                    </div>
                  )}
                </ToolkitProvider>
                <div style={{marginTop:'1vw', marginLeft:isThisPortrait?'2vw':''}}>{txtBL}</div>
              </div>
            </div>
        </div>
     </section>
    </>

  )
}

export default ConfigTenantTable